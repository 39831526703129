<template>
  <div class="wordDict position-absolute z-10 border borderRed cursor-default scrollBar shadow-md" v-if="content">
    <div class="content position-relative">
      <div class="d-flex align-items-center mb-1">
        <a @click.stop="PlayAudioDict()" class="pointer bg-red-main-ai d-flex align-items-center justify-content-center playerBtn mr-2">
          <font-awesome-icon icon="volume-up" class="text-sm text-white" />
        </a>
        <h5 class="font-bold text-xl mb-0">{{ content.key }}</h5>
      </div>
      <div class="translation_KK d-flex flex-wrap">
        <span class="translation_KK_type ml-2">{{ content.phon }}</span>
      </div>
      <p class="translation_KK_expl whitespace-pre-line" v-if="content.expl">{{ content.expl }}</p>
      <!-- 關聯字 -->
      <div v-if="content.linkKeys.length > 0" class="mt-2">
        <div v-for="(el,idx) in subDictInfo" :key="idx">
          <audio :id="'subAudioPlayer'+ idx" :src="`${BaseUrl}/livelibrary/Dict/Word/${el.key}/audio?lang=en`" controlos="controls" :ref="'subAudioPlayer' + idx"></audio>
          <div class="d-flex align-items-center mb-1">
            <a @click.stop="PlaySubAudioDict(idx)" class="pointer bg-red-main-ai d-flex align-items-center justify-content-center playerBtn mr-2">
              <font-awesome-icon icon="volume-up" class="text-sm text-white" />
            </a>
            <h5 class="font-bold text-xl mb-0">{{ el.key }}</h5>

          </div>
          <div class="translation_KK d-flex flex-wrap">
            <span class="translation_KK_type ml-2">{{ el.phon }}</span>
          </div>
          <p class="translation_KK_expl whitespace-pre-line" v-if="el.expl">{{ el.expl }}</p>
        </div>
      </div>
      <!-- 關聯字 -->
      <a class="pointer position-absolute closeBtn d-block" @click.prevent.stop="closeDict()">
        <img src="@/assets/image/ai/closeBtn.png" alt="" class="d-inline-block">
      </a>
    </div>
    <audio v-if="content.key" muted id='audio' :src="`${BaseUrl}/livelibrary/Dict/Word/${content.key}/audio?lang=en`" controlos="controls" ref="audioplayer"></audio>
  </div>
</template>

<script>
  import dictApi from '@/store/library/api'
  export default {
    name: 'WordDict',

    data () {
      return {
        subDictInfo: []
      }
    },
    props: {
      content: {
        type: Object
      }
    },
    computed: {
      BaseUrl () {
        if (process.env.NODE_ENV === 'production') {
          return 'https://api.liveabc.com/v1'
        } else {
          return 'https://apitest.liveabc.com/v1'
        }
      }
    },
    mounted () {
      this.PlayAudioDict()
      this.content.linkKeys.length > 0 ? this.getSubDicts() : null
    },
    methods: {
      closeDict () {
        let self = this
        self.$emit('closeDict')
      },
      async openSubDictionary (el) {
        let self = this
        if(self.subDictInfo !== null) {
          self.subDictInfo = null
        } else {
          const { data } = await dictApi.get(`/v1/livelibrary/Dict/Word/${el}`)
          self.subDictInfo = data.data
        }
      },
      PlayAudioDict () {
        let self = this
        if (self.$refs.audioplayer) {
          let audioplayer = self.$refs.audioplayer
          // 判斷是否撥放中
          audioplayer.onplaying = () => {
            self.isPlaying = true
          }
          audioplayer.onpause = () => {
            self.isPlaying = false
          }
          // 播放切換
          if (self.isPlaying) {
            audioplayer.pause()
            audioplayer.currentTime = 0
            self.isPlaying = false
          } else {
            audioplayer.play()
            self.isPlaying = true
          }
        }
      },
      PlaySubAudioDict (idx) {
        let self = this
        if (self.$refs['subAudioPlayer' + idx]) {
          let subAudioplayer = self.$refs['subAudioPlayer' + idx][0]
          // 判斷是否撥放中
          subAudioplayer.onplaying = () => {
            self.isPlaying = true
          }
          subAudioplayer.onpause = () => {
            self.isPlaying = false
          }
          // 播放切換
          if (self.isPlaying) {
            subAudioplayer.pause()
            subAudioplayer.currentTime = 0
            self.isPlaying = false
          } else {
            subAudioplayer.play()
            self.isPlaying = true
          }
        }
      },
      async getSubDicts () {
        let self = this
        for (let value of self.content.linkKeys) {
          const { data } = await dictApi.get(`/v1/livelibrary/Dict/Word/${value}`)
          self.subDictInfo.push(data.data)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.wordDict {
  width: 22em;
  height: 12em;
  overflow-y: scroll;
  left: 0%;
  top: 100%;
  position: absolute;
  padding-bottom: 1rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  cursor: default;
  border-width: 1px;
  line-height: 2;
  background-color: #F3F3F3;
  box-shadow: 0px 0px 8px 0px #00000080;
  @media (min-width: 768px) {
    display: block;
    z-index: 99;
  }
  .whitespace-pre-line {
    white-space: pre-line;
  }
  .closeBtn {
    top: 0;
    right: 0;
    padding: 0.25rem;
    color: black;
  }
  .playerBtn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
  .content {
    .translation_KK {
      .translation_KK_spell {
        color: #195BBF;
      }
    }
    .translation_KK_expl {
      font-size: 14px !important;
    }
  }
}

.scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar {
  width: 6px;
}

.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
</style>
