<template>
<div>
  <div class="popBg" @click.prevent.stop="closeDict"></div>

  <div class="popup position-fixed">
    <div class="popup__inner position-fixed z-40 rounded p-2">
      <div class="popup__body text-NotoSansRegular text-base py-3 px-3 bg-white" v-if="content">
        <div class="wordDict z-10 px-2 py-2" v-if="content">
          <div class="bg-white position-relative">
            <div class="d-flex items-center">
              <a @click.prevent.stop="PlayAudioDict()" class="pointer bg-red-main-ai d-flex align-items-center justify-content-center playerBtn mr-2">
                <font-awesome-icon icon="volume-up" class="text-sm text-white" />
              </a>
              <h5 class="font-bold mb-1 text-xl textColorRed ml-2">{{ content.key }}</h5>
            </div>
            <div class="translation_KK mb-1 d-flex flex-wrap">
              <span>{{ content.phon }}</span>
              <!-- <span class="ml-2">v.</span> -->
            </div>
            <p class="whitespace-pre-line">{{ content.expl }}</p>

            <!-- 關聯字 -->
            <div v-if="content.linkKeys.length > 0" class="mt-2">
              <div v-for="(el,idx) in subDictInfo" :key="idx">
                <audio :id="'subAudioPlayer'+ idx" :src="`${BaseUrl}/livelibrary/Dict/Word/${el.key}/audio?lang=en`" controlos="controls" :ref="'subAudioPlayer' + idx"></audio>
                <div class="d-flex align-items-center mb-1">
                  <a @click.stop="PlaySubAudioDict(idx)" class="pointer bg-red-main-ai d-flex align-items-center justify-content-center playerBtn mr-2">
                    <font-awesome-icon icon="volume-up" class="text-sm text-white" />
                  </a>
                  <h5 class="font-bold text-xl mb-0">{{ el.key }}</h5>

                </div>
                <div class="translation_KK d-flex flex-wrap">
                  <span class="translation_KK_type ml-2">{{ el.phon }}</span>
                </div>
                <p class="translation_KK_expl whitespace-pre-line" v-if="el.expl">{{ el.expl }}</p>
              </div>
            </div>
            <!-- 關聯字 -->
            <a class="pointer position-absolute closeBtn d-block" @click.prevent.stop="closeDict">
              <img src="@/assets/image/ai/closeBtn.png" alt="" class="d-inline-block">
            </a>
          </div>
          <audio id='audio' :src="`${BaseUrl}/livelibrary/Dict/Word/${content.key}/audio?lang=en`" controlos="controls" ref="audioplayer"></audio>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
  import dictApi from '@/store/library/api'
  export default {
    name: 'WordDictM',

    data () {
      return {
        subDictInfo: []
      }
    },
    mixins: [
    ],
    props: {
      content: {
        type: Object
      }
    },
    computed: {
      BaseUrl () {
        if (process.env.NODE_ENV === 'production') {
          return 'https://api.liveabc.com/v1'
        } else {
          return 'https://apitest.liveabc.com/v1'
        }
      }
    },
    mounted () {
      this.content.linkKeys.length > 0 ? this.getSubDicts() : null
    },
    methods: {
      closeDict () {
        let self = this
        self.$emit('closeDict')
      },
      PlayAudioDict () {
        let self = this
        if (self.$refs.audioplayer) {
          let audioplayer = self.$refs.audioplayer
          // 判斷是否撥放中
          audioplayer.onplaying = () => {
            self.isPlaying = true
          }
          audioplayer.onpause = () => {
            self.isPlaying = false
          }
          // 播放切換
          if (self.isPlaying) {
            audioplayer.pause()
            audioplayer.currentTime = 0
            self.isPlaying = false
          } else {
            audioplayer.play()
            self.isPlaying = true
          }
        }
      },
      PlaySubAudioDict (idx) {
        let self = this
        if (self.$refs['subAudioPlayer' + idx]) {
          let subAudioplayer = self.$refs['subAudioPlayer' + idx][0]
          // 判斷是否撥放中
          subAudioplayer.onplaying = () => {
            self.isPlaying = true
          }
          subAudioplayer.onpause = () => {
            self.isPlaying = false
          }
          // 播放切換
          if (self.isPlaying) {
            subAudioplayer.pause()
            subAudioplayer.currentTime = 0
            self.isPlaying = false
          } else {
            subAudioplayer.play()
            self.isPlaying = true
          }
        }
      },
      async getSubDicts () {
        let self = this
        for (let value of self.content.linkKeys) {
          const { data } = await dictApi.get(`/v1/livelibrary/Dict/Word/${value}`)
          self.subDictInfo.push(data.data)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.wordDict {
  // width: 20em;
  // height: 10em;
  // overflow-y: scroll;
  // left: 10%;
  // top: 105%;
  // @media (max-width:768px) {
    // top: 120%;
    width: 100%;
  // }
  .whitespace-pre-line {
    white-space: pre-line;
  }
  .closeBtn {
    top: 0;
    right: 0;
    padding: 0.25rem;
    color: black;
  }
  .playerBtn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
  .content {
    .translation_KK {
      .translation_KK_spell {
        color: #195BBF;
      }
    }
  }
}
// .scrollBar {
//   max-height: 500px;
//   overflow-y: scroll;
// }
.scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar {
  width: 6px;
}

.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.popBg {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed !important;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.popup {
  z-index: 1000;
  .popup__inner {
    max-width: 32rem;
    top: 45%;
    border-radius: 0.25rem!important;
    left: 0;
    right: 0;
    margin: 0 auto;
    line-height: 1.5;
    letter-spacing: .05em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);

    -webkit-animation-name: example;
    -webkit-animation-duration: .3s;
    animation-name: example;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    .popup__header {
      margin-bottom: 15px;
      font-size: 0.85em;
      font-weight: bold;
    }
    .popup__body {
      font-size: 0.85em;
      .english {
        .enWord {
          margin-right: 2px;
        }
      }
    }
    .popup__buttons {
      text-align: right;
    }
  }
}
@-webkit-keyframes example {
  from {
    opacity: 0;
    margin-top: -77px;
    transform: scale(1);
  }
  to {
    opacity: 1;
    margin-top: -77px;
    transform: scale(1);
    }
}

/* Standard syntax */
@keyframes example {
  from {
    opacity: 0;
    margin-top: -77px;
    transform: scale(1);
  }
  to {
    opacity: 1;
    margin-top: -77px;
    transform: scale(1);
  }
}
</style>
